import React, { useEffect, useRef, useState } from 'react'
import { getLabelFromString } from '../../../functions/functions'
import useOutsideClick from '../../../utils/useOutsideClick'
import { useTranslation } from 'react-i18next'

interface SelectProps {
  options
  name
  changeHandler
  arrayName
  arrayIndex
  value
  placeholder
  search
  nameKey?: string
}

const SelectInputWithCheck = (props: SelectProps) => {
  const {
    options,
    name,
    changeHandler,
    arrayName,
    arrayIndex,
    value,
    placeholder,
    search,
    nameKey,
  } = props

  const [items, setItems] = useState(options)
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [selected, setSelected] = useState(
    value
      ? options.findIndex((item) => {
          return (
            (item.id && item.id === (value.id || value)) ||
            item === value ||
            (item.value && item.value === value)
          )
        })
      : -1
  )

  const node = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setItems(options)
  }, [options])

  useEffect(() => {
    setSelected(
      value
        ? options.findIndex((item) => {
            return (
              (item.id && item.id === (value?.id || value)) ||
              item === value ||
              (item.value && item.value === value)
            )
          })
        : -1
    )
  }, [value, options])

  const handlerChange = (name, index, value, data) => {
    if (changeHandler) {
      changeHandler(value, name, arrayName, arrayIndex, data)
    }
    if (index || parseInt(index) === 0) setSelected(index)

    toggleSelect(false, false)
  }

  useOutsideClick(node, () => {
    toggleSelect(false, false)
  })

  const filterItems = (e) => {
    const newItems = options.filter((option) => {
      const optionName = nameKey ? option[nameKey] : option.name

      return (
        optionName.substr(0, e.target.value.length).toUpperCase() == e.target.value.toUpperCase()
      )
    })
    setSelected(-1)
    setItems(newItems)
  }

  const toggleSelect = (value, e) => {
    if (!e || !e.target || !e.target.classList.contains('js-select-search')) setIsOpenSelect(value)
  }

  const { t } = useTranslation()

  const selectedLabel =
    nameKey && options[selected]
      ? options[selected][nameKey]
      : options[selected]?.label ||
        (typeof options[selected]?.name !== 'undefined' && !options[selected]?.name
          ? t('Not set')
          : options[selected]?.name ||
            getLabelFromString(options[selected] || placeholder || t('Please select')))
  // @ts-ignore
  return (
    // @ts-ignore
    <div className="select__one" ref={node}>
      <div className="select__once--field" onClick={(e) => toggleSelect(!isOpenSelect, e)}>
        <input type="text" placeholder={placeholder} value={selectedLabel} />
        <div
          className="select__main--dropdown"
          style={{ display: isOpenSelect ? 'block' : 'none' }}
        >
          {!!search && (
            <div className="search__select--field">
              <input
                type="text"
                className={'js-select-search'}
                placeholder={t('Search...')}
                onKeyUp={(e) => filterItems(e)}
              />
            </div>
          )}
          <div className="search__select--dropdown">
            <ul>
              {options.map((item, index) => {
                if (!items.find((findItem) => findItem === item || findItem.id === item.id)) {
                  return null
                }
                return (
                  <SelectInputOption
                    key={index}
                    index={index}
                    item={item}
                    selected={selected}
                    nameKey={nameKey}
                    handlerChange={handlerChange}
                    name={name}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const SelectInputOption = ({ item, selected, handlerChange, index, name, nameKey }) => {
  const { t } = useTranslation()

  let labelValue = nameKey ? item[nameKey] : item.label || item.name || getLabelFromString(item)

  if (
    typeof labelValue === 'object' &&
    !React.isValidElement(labelValue) &&
    (typeof item.label !== 'undefined' || typeof item.name !== 'undefined')
  )
    labelValue = t('Not set')

  return (
    <li
      key={index}
      className={
        '' +
        (Array.isArray(selected)
          ? selected.includes(item)
            ? ' active__select--dropdown'
            : ''
          : selected === index
          ? ' active__select--dropdown'
          : '')
      }
    >
      <a onClick={() => handlerChange(name, index, item.value || item.id || item, item)} href="#">
        {item.img && <img src={item.img.disk_name || item.img} alt="" />}
        {item.icon && <img src={item.icon.disk_name || item.icon} alt="" />}
        <span>{labelValue}</span>
      </a>
    </li>
  )
}

export default SelectInputWithCheck

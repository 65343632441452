import Header from './components/Header'
import OrderForm from './containers/OrderForm'
import { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { socket } from './context/socket'
import { connect } from 'react-redux'
import { setAppLanguages, setDataFile, updateTranslationItem } from './redux-store/actions'
import apiDirectory from './api/directory'
import store from './redux-store'
import { withTranslation } from 'react-i18next'
import axios from 'axios'

function App({ translations, updateTranslation, setFileData, i18n }) {
  axios.defaults.headers.common['Accept-Language'] = i18n.language
  const [currentTranslations, setCurrentTranslations] = useState(translations)

  const updatePageNumber = (jsonObject: {
    id: string | number
    pages: any
    page: number
    timePerPage: any
  }) => {
    if (
      jsonObject.id &&
      jsonObject.pages &&
      currentTranslations &&
      currentTranslations[jsonObject.id]
    ) {
      const newTranslation = { ...translations[jsonObject.id] }
      newTranslation.pages = jsonObject.pages
      newTranslation.page = jsonObject.page || 0
      newTranslation.timePerPage = jsonObject.timePerPage
      newTranslation.uploadProgress = 100

      if (
        currentTranslations &&
        newTranslation &&
        currentTranslations[jsonObject.id] &&
        currentTranslations[jsonObject.id].page !== newTranslation.page
      ) {
        updateTranslation(newTranslation)
      }
    }
  }

  const setDataFilePath = (path: any) => {
    console.log('path', path)
    setFileData(path)
  }

  useEffect(() => {
    /**
     * Detects pages
     */

    socket.off('dataFile', setDataFilePath).on('dataFile', setDataFilePath)
    return () => {
      socket.off('dataFile', setDataFilePath)
    }
  }, [])

  useEffect(() => {
    /**
     * Detects pages
     */

    socket.off('detect-status', updatePageNumber).on('detect-status', updatePageNumber)
    return () => {
      socket.off('detect-status', updatePageNumber)
    }
  }, [Object.keys(translations)])

  useEffect(() => {
    setCurrentTranslations(translations)
  }, [translations])

  useEffect(() => {
    getAppLanguages()
  }, [])

  const getAppLanguages = () => {
    return apiDirectory
      .getAll(`/app_languages?filterDomain=transcription.kingsoftranslation.com`)
      .then((res: any) => {
        console.log('res', res)
        if (!(res.response && res.response.data)) store.dispatch(setAppLanguages(res))
      })
  }

  return (
    <div className="App">
      <Header />
      <OrderForm />
      <ToastContainer position={'top-right'} className={'notification-toast'} />
    </div>
  )
}

// @ts-ignore
const mapStateToProps = ({ translation: { items } }) => ({
  translations: items,
})
const mapDispatchToProps = (dispatch: (arg0: { type: string; payload: any }) => any) => ({
  updateTranslation: (data: any) => dispatch(updateTranslationItem(data)),
  setFileData: (data: any) => dispatch(setDataFile(data)),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App))

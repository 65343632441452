const getAudioTime = (file: File): Promise<object> => {
  console.log('file', file)
  return new Promise((resolve, reject) => {
    try {
      // Create instance of FileReader
      const reader = new FileReader()

      // When the file has been succesfully read
      reader.onload = function (event) {
        // Create an instance of AudioContext
        const audioContext = new window.AudioContext()

        // Asynchronously decode audio file data contained in an ArrayBuffer.
        // @ts-ignore
        audioContext.decodeAudioData(
          event.target.result,
          function (buffer) {
            // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
            const duration = buffer.duration

            // example 12.3234 seconds
            // Alternatively, just display the integer value with
            // parseInt(duration)
            // 12 seconds

            const minutes = Math.floor(duration / 60)
            const seconds = Math.floor(duration - minutes * 60)
            return resolve({ totalSeconds: duration, minutes, seconds })
          },
          function (error) {
            return reject({ error })
          }
        )
      }

      // In case that the file couldn't be read
      reader.onerror = function (event) {
        return reject({ error: event })
      }

      // Read file as an ArrayBuffer, important !
      reader.readAsArrayBuffer(file)
    } catch (e) {
      return reject({ error: e })
    }
  })
}
export default getAudioTime

import StripePayment from './StripePayment'
import PaypalPayment from './PaypalPayment'
import { useState } from 'react'
import RadioButton from '../common/blocks/RadioButton'
import { useTranslation } from 'react-i18next'

const Payment = (props) => {
  const { t } = useTranslation()

  const [type, setType] = useState('stripe')

  return (
    <div className="payment__method--grid">
      <div className="elem__payment--method" onClick={() => setType('stripe')}>
        <p>
          <span>
            <img src="/images/paym1.svg" alt="paym" />
          </span>{' '}
          {t('Card')}
        </p>
        <div className="form-group">
          <div className="row-group">
            <div className="row-group__item">
              <RadioButton
                changeValue={() => setType('stripe')}
                selected={type === 'stripe' ? 1 : 0}
                name="payment_type"
                value={1}
              />
            </div>
          </div>
        </div>
      </div>
      {type === 'stripe' && <StripePayment {...props} />}
      <div className="elem__payment--method" onClick={() => setType('paypal')}>
        <p>
          <span>
            <img src="/images/paym2.svg" alt="paym" />
          </span>{' '}
          {t('Paypal')}
        </p>
        <div className="form-group">
          <div className="row-group">
            <div className="row-group__item">
              <RadioButton
                changeValue={() => setType('paypal')}
                selected={type === 'paypal' ? 1 : 0}
                name="payment_type"
                value={1}
              />
            </div>
          </div>
        </div>
      </div>
      {type === 'paypal' && <PaypalPayment {...props} />}
    </div>
  )
}
export default Payment

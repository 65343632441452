import steps from '../constans/steps'
import { useState } from 'react'
import progressdone from '../images/progressdone.svg'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep'
import FourthStep from './FourthStep'
import FifthStep from './FifthStep'
import Sidebar from '../components/Sidebar'
import SuccessPage from './SuccessPage'
import { connect } from 'react-redux'
import { PaymentIntentDefault, PaymentIntent } from '../types/common'
import useOrder from '../hooks/useOrder'

declare let window: any
const checkActive = (active) => (active ? ' active__progress' : '')
const checkDone = (done) => (done ? ' done__progress' : '')

const OrderForm = ({ calculating, translations }) => {
  const success = new URLSearchParams(window.location.search).get('success')
  const paymentTesting = new URLSearchParams(window.location.search).get('paymentTesting')
  const [stepsData, setStep] = useState(steps)
  const [currentStep, setCurrentStep] = useState(success ? 6 : paymentTesting ? 5 : 1)
  const [data, setData] = useState<{
    options: undefined | any[]
    turnaround_time: undefined | any
    name: undefined
    email: undefined
    languages_from: undefined | string
    languages_to: undefined | any[]
  }>({
    options: undefined,
    turnaround_time: undefined,
    name: undefined,
    email: undefined,
    languages_from: undefined,
    languages_to: undefined,
  })

  const [files, setFiles] = useState<File[] | undefined[]>([])
  const [pricing, setPricing] = useState<any>({
    price: undefined,
    options: undefined,
  })
  const [loading, setLoading] = useState(false)
  const [totalPrice, setTotalPrice] = useState(paymentTesting ? 5 : 0)
  const [calculated, setCalculated] = useState(false)
  const [orderId, setOrderId] = useState(false)
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>(PaymentIntentDefault)

  const { handleChangeStep, placeOrder, changeData, addFiles, deleteFile } = useOrder({
    data,
    paymentIntent,
    setPaymentIntent,
    loading,
    setLoading,
    totalPrice,
    orderId,
    setOrderId,
    translations,
    currentStep,
    setCurrentStep,
    stepsData,
    calculating,
    files,
    setFiles,
    pricing,
    setStep,
    calculated,
    setCalculated,
    setTotalPrice,
    setPricing,
    setData,
  })

  const stepsComponent = {
    1: (
      <FirstStep
        data={data}
        changeData={changeData}
        handleChangeStep={handleChangeStep}
        stepsData={stepsData}
        currentStep={currentStep}
        pricing={pricing}
        loading={loading}
        totalPrice={totalPrice}
        translations={translations}
      />
    ),
    2: (
      <SecondStep
        data={data}
        changeData={changeData}
        addFiles={addFiles}
        setCalculated={setCalculated}
        files={files}
        onDelete={deleteFile}
        handleChangeStep={handleChangeStep}
        stepsData={stepsData}
        currentStep={currentStep}
        pricing={pricing}
        loading={loading}
        totalPrice={totalPrice}
        translations={translations}
      />
    ),
    3: <ThirdStep data={data} changeData={changeData} />,
    4: <FourthStep data={data} changeData={changeData} pricing={pricing} />,
    5: (
      <FifthStep
        data={data}
        changeData={changeData}
        totalPrice={totalPrice}
        placeOrder={placeOrder}
        loading={loading}
      />
    ),
    6: (
      <SuccessPage
        totalPrice={totalPrice}
        orderId={orderId}
        data={data}
        loading={loading}
        placeOrder={placeOrder}
      />
    ),
  }

  const Step = () => {
    return stepsComponent[currentStep]
  }

  return (
    <div className={`checkout__long simplified__block ${paymentTesting ? ' paymentTesting ' : ''}`}>
      {currentStep !== 6 && (
        <div className="checkout__progress--section">
          {stepsData &&
            stepsData.map((item) => {
              return (
                <div
                  onClick={() => {
                    handleChangeStep(false, item.id)
                  }}
                  key={item.id}
                  className={
                    'element__progress--wrapper' + checkActive(item.active) + checkDone(item.done)
                  }
                >
                  <div
                    className={
                      'element__progress' + checkActive(item.active) + checkDone(item.done)
                    }
                  >
                    {!item.active && item.done ? (
                      <span>
                        <img src={progressdone} />
                      </span>
                    ) : (
                      <span>{item.id}</span>
                    )}
                    <p>{item.label}</p>
                  </div>
                </div>
              )
            })}
        </div>
      )}

      <div className={'content__guest ' + (!!currentStep && `step-${currentStep}`)}>
        {Step()}
        {currentStep !== 6 && (
          <Sidebar
            handleChangeStep={handleChangeStep}
            stepsData={stepsData}
            data={data}
            currentStep={currentStep}
            pricing={pricing}
            loading={loading}
            totalPrice={totalPrice}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ translation: { calculating, items } }) => ({
  calculating: calculating,
  translations: items,
})
export default connect(mapStateToProps)(OrderForm)

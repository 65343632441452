import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const FourthStep = ({ pricing, data, changeData }) => {
  const selectOption = (option, childOption) => {
    let options = { ...data.options }
    if (!options) {
      options = {}
    }

    options[option.id] = { ...childOption, ...{ parentName: option.name } }

    changeData('options', options)
  }

  const selectTime = (value) => {
    changeData('turnaround_time', value)
  }

  const { t } = useTranslation()
  return (
    <>
      <div className="guest__content--main">
        <div className="translation__wrapper--picker">
          {pricing &&
            pricing.options.map((option, index) => {
              if ((!data.options || Object.keys(data.options).length < index) && index !== 0) {
                return null
              }

              if (index > 0 && pricing.selectedSize && !data.turnaround_time) {
                return null
              }

              return (
                <React.Fragment key={index}>
                  <div className="translation__picker--element picker__type">
                    <div className="translation__picker--title">
                      <h2>{option.name}</h2>
                    </div>
                    <div className="picker__choose--grid">
                      {option.PricingOptionOptions.map((item, index) => {
                        return (
                          <label
                            key={index}
                            className={
                              'elem__choose--grid' +
                              (data.options && data.options[option.id]?.id === item.id
                                ? ' active__choose--grid'
                                : ' ')
                            }
                            onClick={() => {
                              selectOption(option, item)
                            }}
                          >
                            <div className="choose__grid--switch">
                              <div className="row-group__item">
                                <div className="radio">
                                  <div className="radio__box">
                                    <input
                                      className="radio__input"
                                      type="radio"
                                      checked={
                                        data.options && data.options[option.id]?.id === item.id
                                      }
                                      name={`Option[${option.id}]`}
                                    />
                                    <span className="radio__checkmark" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="choose__grid--info">
                              <h6>
                                <p>{item.name}</p>
                                {!item.price ? (
                                  <span>{t('FREE')}</span>
                                ) : (
                                  <span>
                                    ${item.price}
                                    {item.matt && item.matt !== 'fixed' && (
                                      <>&nbsp;Per {item.mattLabel || item.matt}</>
                                    )}
                                  </span>
                                )}
                              </h6>
                              <p>{item.description}</p>
                            </div>
                          </label>
                        )
                      })}
                    </div>
                  </div>

                  {data.options &&
                    Object.keys(data.options).length > 0 &&
                    pricing.selectedSize &&
                    index === 0 && (
                      <div className={'translation__picker--element picker__turnaround'}>
                        <div className="translation__picker--title">
                          <h2>{t('Turnaround Time')}</h2>
                        </div>
                        <div className="picker__choose--grid">
                          <label
                            className={
                              'elem__choose--grid ' +
                              (data.turnaround_time && !data.turnaround_time.urgency_fee
                                ? ' active__choose--grid'
                                : ' ')
                            }
                            onClick={() => {
                              selectTime({
                                from: pricing.selectedSize?.standard_from,
                                to: pricing.selectedSize?.standard_to,
                                type: pricing.selectedSize?.standard_type,
                              })
                            }}
                          >
                            <div className="choose__grid--switch">
                              <div className="row-group__item">
                                <div className="radio">
                                  <div className="radio__box">
                                    <input
                                      className="radio__input"
                                      type="radio"
                                      value="1"
                                      name="turnaround_time"
                                      checked={
                                        data.turnaround_time && !data.turnaround_time.urgency_fee
                                      }
                                      data-radio-toggle=""
                                    />
                                    <span className="radio__checkmark"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="choose__grid--info">
                              <h6>
                                <p>{t('STANDART')}</p> <span>{t('Best price')}</span>
                              </h6>
                              <TurnAround data={pricing.selectedSize} type={'standard'} />
                            </div>
                          </label>
                          <label
                            className={
                              'elem__choose--grid ' +
                              (data.turnaround_time && data.turnaround_time.urgency_fee
                                ? ' active__choose--grid'
                                : ' ')
                            }
                          >
                            <div className="choose__grid--switch">
                              <div className="row-group__item">
                                <div
                                  className="radio"
                                  onClick={() => {
                                    selectTime({
                                      urgency_fee: pricing.selectedSize?.urgency_fee,
                                      from: pricing.selectedSize?.urgent_from,
                                      to: pricing.selectedSize?.urgent_to,
                                      type: pricing.selectedSize?.urgent_type,
                                    })
                                  }}
                                >
                                  <div className="radio__box">
                                    <input
                                      className="radio__input"
                                      type="radio"
                                      value="1"
                                      name="turnaround_time"
                                      checked={
                                        data.turnaround_time && data.turnaround_time.urgency_fee
                                      }
                                    />
                                    <span className="radio__checkmark"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="choose__grid--info">
                              <h6>
                                <p>{t('EXPEDITED')}</p>{' '}
                                <span>
                                  {pricing.selectedSize.urgency_fee}% {t('RUSH FEE')}{' '}
                                </span>
                              </h6>
                              <TurnAround data={pricing.selectedSize} type={'urgent'} />
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                </React.Fragment>
              )
            })}
        </div>
      </div>
    </>
  )
}

export const TurnAround = ({ data, type }) => {
  const prefix = type ? `${type}_` : ''
  const { t } = useTranslation()

  const to =
    data[`${prefix}type`] === 'hours' ||
    (data[`${prefix}type`] === 'days' && data[`${prefix}to`] < 4)
      ? moment().add(data[`${prefix}to`], data[`${prefix}type`]).calendar()
      : moment().add(data[`${prefix}to`], data[`${prefix}type`]).format('ll')

  return (
    <p>
      <span>
        {data[`${prefix}from`]} - {data[`${prefix}to`]} {t(data[`${prefix}type`])}{' '}
      </span>
      {to} ({t('latest')})
    </p>
  )
}

export default FourthStep

import { useTranslation } from 'react-i18next'

const TranslationItemButtons = ({ item, id, deleteItem, editItem }) => {
  const { t } = useTranslation()

  return (
    <>
      {!!(!item.error && item.totalSeconds) && (
        <span className="upload-edit">
          {!!item.minutes && (
            <b>
              {item.minutes}
              <span>{item.minutes === 1 ? t('minute') : t('minutes')}</span>
            </b>
          )}
          &nbsp;
          <b>
            {item.seconds}
            <span>{item.seconds === 1 ? t('second') : t('seconds')}</span>
          </b>
        </span>
      )}

      {item.error ? (
        <div className="form-group__buttons">
          <div className="upload-content__files-list__options">
            <button
              className=" button button-close"
              onClick={() => {
                deleteItem(id)
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`upload-content__files-list__options ${
            editItem ? 'upload-content__files-list__options-edit' : ''
          }`}
        >
          {!!editItem && (
            <button
              className="button button-edit"
              onClick={() => {
                editItem(id)
              }}
            />
          )}
          <button
            className="button button-close"
            onClick={() => {
              deleteItem(id)
            }}
          />
        </div>
      )}
    </>
  )
}
export default TranslationItemButtons

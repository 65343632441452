import { takeEvery, put } from 'redux-saga/effects'
import {
  ADD_FILES,
  ADD_TRANSLATION,
  ADD_TRANSLATION_ITEM,
  DELETE_TRANSLATION,
  DELETE_TRANSLATION_ITEM,
  SET_CROSS_PARAMS,
  UPDATE_COUNTER,
  UPDATE_TRANSLATION,
  UPDATE_TRANSLATION_ITEM,
} from '../actions/types'
import { safe } from './errorHandler'
import uniqId from '../../utils/uniqId'
import store from '../index'
import { setCalculating, updateTranslationItem } from '../actions'
import getAudioTime from '../../utils/getAudioTime'

const cancelFileUploadProgress = function (filesInfo, error) {
  if (filesInfo) {
    for (const key of Object.keys(filesInfo)) {
      const newFileData = {
        ...filesInfo[key],
        ...{ error: error },
      }
      store.dispatch(updateTranslationItem(newFileData))
    }
  }
}

const addFiles = function* ({ payload }) {
  if (payload && payload.length) {
    store.dispatch(setCalculating(true))
    const filesInfo = {}
    for (const file of payload) {
      const fileData: any = {
        type: file.data.type,
        name: file.name,
        data: file.data,
      }
      fileData.id = uniqId()
      filesInfo[fileData.id] = fileData
      yield put({ type: ADD_TRANSLATION_ITEM, payload: fileData })
    }

    for (const key of Object.keys(filesInfo)) {
      const info = { [key]: filesInfo[key] }

      const itemData = new FormData()
      itemData.append(key, filesInfo[key].data)

      try {
        const audioData = yield getAudioTime(filesInfo[key].data)
        console.log('audioData', audioData)
        const compositeData = {
          ...filesInfo[key],
          ...audioData,
        }

        yield put({ type: UPDATE_TRANSLATION_ITEM, payload: compositeData })

        yield put({ type: SET_CROSS_PARAMS, payload: { [key]: compositeData } })
        yield put({ type: UPDATE_COUNTER })
      } catch (error) {
        cancelFileUploadProgress(info, error)
      }
    }
    store.dispatch(setCalculating(false))
  }
}

const addTranslation = function* ({ payload }) {
  console.log('')

  yield put({ type: ADD_TRANSLATION_ITEM, payload: payload })
  yield put({ type: UPDATE_COUNTER })
}
const deleteTranslation = function* ({ payload }) {
  yield put({ type: DELETE_TRANSLATION_ITEM, payload: payload })
  yield put({ type: UPDATE_COUNTER })
}
const updateTranslation = function* ({ payload }) {
  yield put({ type: UPDATE_TRANSLATION_ITEM, payload: payload })
  yield put({ type: UPDATE_COUNTER })
}

const translationSagas = [
  takeEvery(ADD_TRANSLATION, safe(addTranslation)),
  takeEvery(DELETE_TRANSLATION, safe(deleteTranslation)),
  takeEvery(UPDATE_TRANSLATION, safe(updateTranslation)),
  takeEvery(ADD_FILES, safe(addFiles)),
]

export default translationSagas

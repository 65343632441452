import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'

import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import checkRequired from '../../functions/isRequired'
import FormLoading from '../common/blocks/FormLoading'

const currency = 'USD'

export default function PaypalPayment({ placeOrder, amount, loading, data, shippingRequired }) {
  const { t } = useTranslation()

  const [valid, setValid] = useState(false)
  useEffect(() => {
    setValid(validate())
  }, [data])

  const submitForm = useCallback(
    (e) => {
      e.preventDefault()
      if (!valid) {
        toast.error(t('Fill all shipping information!'))
      } else {
        toast.info(t('Click PayPal button to proceed!'))
      }
    },
    [data, valid]
  )

  const validate = useCallback(() => {
    if (shippingRequired) {
      const { isValid } = checkRequired(data, ['recipient', 'phone', 'country', 'address'], t, true)
      return isValid
    }
    return true
  }, [data])

  if (loading) {
    return <FormLoading loadingText={t('Loading...')} />
  }
  if (!valid) {
    return (
      <div className="elem__payment--method">
        <button className="pointer">{t('Fill all shipping information!')}</button>
      </div>
    )
  }

  return (
    <form id={'payment-form'} onSubmit={submitForm}>
      <PayPalScriptProvider
        options={{
          'client-id': process.env.REACT_APP_PAYPAL_CLIENTID || '',
        }}
      >
        <PayPalButtons
          fundingSource={'paypal'}
          style={{ layout: 'horizontal' }}
          createOrder={(data, actions) => {
            return actions.order
              .create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value: amount,
                    },
                  },
                ],
              })
              .then((orderId) => {
                // Your code here after create the order
                return orderId
              })
          }}
          onApprove={function (data, actions: any) {
            return actions.order.capture().then(function () {
              console.log('onApprove', data)
              placeOrder({
                noPayment: false,
                emailChanged: false,
                elements: false,
                stripe: false,
                paypal: data,
              })
              // Your code here after capture the order
            })
          }}
          onError={function (err: any) {
            toast.error(err.message)
          }}
        />
      </PayPalScriptProvider>
    </form>
  )
}

import { SET_APP_LANGUAGES } from '../actions/types'

interface IInitState {
  app_languages: any[]
}

const initialState: IInitState = {
  app_languages: [],
}

export default function (state = initialState, action): IInitState {
  switch (action.type) {
    case SET_APP_LANGUAGES:
      return {
        ...state,
        app_languages: action.payload,
      }
    default:
      return state
  }
}

import { Trans, useTranslation } from 'react-i18next'
import SummaryButton from '../components/common/blocks/SummaryButton'

const FirstStep = (props) => {
  const { changeData, data } = props
  const { t } = useTranslation()

  return (
    <>
      <div className="guest__content--main">
        <div className="get__started">
          <div className="head__started">
            <h2>
              <Trans>Get started</Trans>
            </h2>
            <p>
              <Trans>Enter your contact information to begin the checkout process.</Trans>
            </p>
          </div>
          <div className="started__form">
            <div className="field__custom">
              <input
                type="text"
                placeholder={t('Full name')}
                onChange={(e) => {
                  changeData('name', e.target.value)
                }}
                defaultValue={data.name}
                name={'name'}
              />
            </div>
            <div className="field__custom">
              <input
                type="email"
                placeholder={t('Email')}
                onChange={(e) => {
                  changeData('email', e.target.value)
                }}
                defaultValue={data.email}
                name={'email'}
              />
            </div>
          </div>
          <SummaryButton {...props} />
          <div
            onClick={() => window.open('https://www.kingsoftranslation.com/quote/', '_blank')}
            style={{ cursor: 'pointer' }}
            className="started__note"
          >
            <p>
              <span>
                <Trans>Note</Trans>:
              </span>{' '}
              <Trans>
                This form is for people who would like to quickly receive a quote and place their
                order in seconds. If you have any questions to ask us first, or if you prefer to
                receive a quote now and then confirm your order at a later time, that’s great too!
                The old form can be found <b>here</b>
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default FirstStep

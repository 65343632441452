import React, { useEffect, useRef, useState } from 'react'
import { getLabelFromString } from '../../../functions/functions'
import useOutsideClick from '../../../utils/useOutsideClick'
import { useTranslation } from 'react-i18next'

const SelectInput = ({
  options,
  className,
  name,
  changeHandler,
  arrayName,
  arrayIndex,
  value,
  labelImg,
  placeholder,
  search,
  required,
  nameKey,
  defaultOption,
}) => {
  const [items, setItems] = useState(options)
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [selected, setSelected] = useState(
    value
      ? items.findIndex((item) => {
          return (
            (item.id && item.id === value) || item === value || (item.value && item.value === value)
          )
        })
      : -1
  )

  useEffect(() => {
    setItems(options)
  }, [options])

  useEffect(() => {
    setSelected(
      value
        ? items.findIndex((item) => {
            return (
              (item.id && item.id === value) ||
              item === value ||
              (item.value && item.value === value)
            )
          })
        : -1
    )
  }, [value])

  const handlerChange = (name, index, value, data) => {
    if (changeHandler) {
      changeHandler(value, name, arrayName, arrayIndex, data)
    }
    if (index || parseInt(index) === 0) setSelected(index)
  }
  const node = useRef<HTMLDivElement>(null)

  useOutsideClick(node, () => {
    toggleSelect(false, false)
  })

  const filterItems = (e) => {
    const newItems = options.filter((option) => {
      const optionName = nameKey ? option[nameKey] : option.name

      return (
        optionName.substr(0, e.target.value.length).toUpperCase() == e.target.value.toUpperCase()
      )
    })
    setItems(newItems)
  }

  const toggleSelect = (value, e) => {
    if (!e || !e.target || !e.target.classList.contains('js-select-search')) setIsOpenSelect(value)
  }

  const { t } = useTranslation()

  const selectedLabel =
    nameKey && items[selected]
      ? items[selected][nameKey]
      : items[selected]?.label ||
        (typeof items[selected]?.name !== 'undefined' && !items[selected]?.name
          ? t('Not set')
          : items[selected]?.name ||
            getLabelFromString(items[selected] || placeholder || t('Please select')))

  // @ts-ignore
  return (
    <div
      // @ts-ignore
      ref={node}
      className={'select js-select ' + className + (isOpenSelect ? ' is-expanded' : '')}
      onClick={(e) => toggleSelect(!isOpenSelect, e)}
    >
      <button
        className="select__label js-select-label"
        type="button"
        data-selected={
          items[selected]?.id || items[selected]?.value || (items[selected] ?? t('Please select'))
        }
      >
        {labelImg ? (
          <img src={labelImg} alt="" />
        ) : (
          items[selected]?.img && <img src={items[selected]?.img} alt="" />
        )}
        <span className={selected > -1 ? 'selected' : 'default-label'}>{selectedLabel}</span>
      </button>
      <div
        className="select__dropdown js-select-dropdown"
        style={{ display: isOpenSelect ? 'block' : '' }}
      >
        {search && (
          <div className="select__search">
            <input
              className="input input--border-alto input--sm js-select-search"
              type="text"
              placeholder={t('Type something')}
              onKeyUp={(e) => filterItems(e)}
            />
          </div>
        )}
        {items.length > 0 && (
          <div className="custom-scroll select__dropdown-scroll">
            <div className="select__options js-select-options">
              {!!defaultOption && (
                <SelectInputOption
                  item={defaultOption}
                  selected={selected}
                  name={name}
                  handlerChange={undefined}
                  index={undefined}
                  nameKey={undefined}
                />
              )}
              {items.map((item, index) => {
                return (
                  <SelectInputOption
                    key={index}
                    index={index}
                    item={item}
                    selected={selected}
                    nameKey={nameKey}
                    handlerChange={handlerChange}
                    name={name}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
      <input
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
        required={required}
        className="select__input js-select-input checkbox__input"
        name={name}
        type="text"
        value={items[selected]?.id || items[selected]?.value || (items[selected] ?? '')}
      />
    </div>
  )
}

const SelectInputOption = ({ item, selected, handlerChange, index, name, nameKey }) => {
  const { t } = useTranslation()

  let labelValue = nameKey ? item[nameKey] : item.label || item.name || getLabelFromString(item)

  if (
    typeof labelValue === 'object' &&
    !React.isValidElement(labelValue) &&
    (typeof item.label !== 'undefined' || typeof item.name !== 'undefined')
  )
    labelValue = t('Not set')

  return (
    <div
      className={
        'select__option js-select-option' +
        (Array.isArray(selected)
          ? selected.includes(item)
            ? ' is-selected'
            : ''
          : selected === index
          ? ' is-selected'
          : '')
      }
      data-option={item.id}
      onClick={() => handlerChange(name, index, item.value || item.id || item, item)}
    >
      {item.img && <img src={item.img.disk_name || item.img} alt="" />}
      {item.icon && <img src={item.icon.disk_name || item.icon} alt="" />}
      <span>{labelValue}</span>
    </div>
  )
}

export default SelectInput

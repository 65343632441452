import React from 'react'
import { useTranslation } from 'react-i18next'
import simpleimage from '../images/simpleimage.svg'
import Languages from './header/Languages'

const Header = () => {
  const { t } = useTranslation()

  return (
    <header className="simplified__header ">
      <div className="container-fluid">
        <div className="outer__header">
          <div className="header__logo">
            <a href="https://www.kingsoftranslation.com">
              <img src={simpleimage} alt="simpleimgae" />
            </a>
            <Languages />
          </div>
          <div className="header__info">
            <p>
              <span>{t('Any questions?')}</span> {t('Call or Text:')}{' '}
              <a href="tel:+16468765461">1-646-876-5461</a> {t('Email:')}{' '}
              <a href="mailto:info@kingsoftranslation.com">info@kingsoftranslation.com</a>{' '}
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
